import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface NOTICE {
  isErroring: boolean;
  message?: string;
  type?: "error" | "info" | "success" | "warning";
}

export interface State {
  loading: boolean;
  notice: NOTICE;
}

const initialState: State = {
  loading: false,
  notice: { isErroring: false, message: "", type: "error" },
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNotice: (state, action: PayloadAction<NOTICE>) => {
      state.notice = { ...state.notice, ...action.payload };
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices
});

export const { setLoading, setNotice } = commonSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file.
export const selectLoading = (state: RootState) => state.common.loading;
export const selectNotice = (state: RootState) => state.common.notice;
export default commonSlice.reducer;
