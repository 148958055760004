import Header from "@components/Header";
import Footer from "@components/Footer";
import styles from "./index.module.scss";
import Cookies from "js-cookie";
import { useAppDispatch } from "@store/hooks";
import { clearUserInfo } from "@store/userSlice";
import { selectLoading } from "@store/commonSlice";
import ScrollTop from "@components/ScrollToTop";
import Fab from "@mui/material/Fab";
import { useAppSelector } from "@store/hooks";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Loading from "@components/Loading";
import { useNavigate } from "react-router";

interface PROPS {
  includeLayout?: boolean;
  children: JSX.Element;
}

function Layout(props: PROPS): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const { includeLayout = true } = props;

  const onLogout = async () => {
    await clearAllStorageUserInfo();
    await setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  // remove all storage user info, includes redux, localstorage, cookies
  const clearAllStorageUserInfo = () => {
    window.localStorage.removeItem("user_info");
    window.localStorage.removeItem("user_id");
    Cookies.remove("fb_access_token");
    dispatch(clearUserInfo());
  };

  const pages = [{ name: "首頁" }];
  const settings = [
    {
      name: "權限 debug",
      onClick: () => {
        navigate(`/debug`);
      },
    },
    {
      name: "登出",
      onClick: onLogout,
    },
  ];

  return (
    <>
      {includeLayout && <Header pageList={pages} settingList={settings} />}
      <div className={styles.layout}>{props.children}</div>
      {includeLayout && <Footer />}
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon color="primary" />
        </Fab>
      </ScrollTop>
      <Loading isVisible={loading} />
    </>
  );
}

export default Layout;
