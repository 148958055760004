import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserInfo } from "@pages/Login/apis/facebook";
import { RootState } from "@store/store";

export interface State {
  me: USER_INFO | null;
  status: "idle" | "loading" | "failed";
}

interface USER_INFO {
  facebook_account: {
    asid: string;
    avatar_url: string;
    username: string;
    email: string;
  };
}

const initialState: State = {
  me: null,
  status: "idle",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(setUserInfo())`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const gerUserInfoAsync = createAsyncThunk(
  "user/fetchUserInfo",
  async (params: { accessToken: string; userId: string }) => {
    const curUser = await new Promise<any>((resolve) => {
      const onGetUserInfoSuccess = (res: any) => {
        const curUser = {
          facebook_account: {
            asid: userId,
            avatar_url: res?.picture?.data?.url,
            username: res.name,
            email: res.email,
          },
        };
        window.localStorage.setItem("user_info", JSON.stringify(curUser));
        resolve(curUser);
      };

      const { userId, accessToken } = params;
      getUserInfo(accessToken, userId, onGetUserInfoSuccess);
    });
    return curUser;
  }
);

export const userSlice = createSlice({
  name: "userInfo",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserInfo: (state, action: PayloadAction<USER_INFO>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.me = action.payload;
    },
    clearUserInfo: (state) => {
      state = initialState;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(gerUserInfoAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(gerUserInfoAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.me = action.payload;
      })
      .addCase(gerUserInfoAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setUserInfo, clearUserInfo } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file.
export const selectUserInfo = (state: RootState) => state.user.me;

export default userSlice.reducer;
