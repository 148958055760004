import { lazy } from "react";
import { RouterItem } from "utils";

const commonPath = "/";

const routeList: Array<RouterItem> = [
  {
    name: "login",
    path: `${commonPath}login`,
    component: lazy(() => import("@pages/Login")),
    isAuthRoute: false,
    includeLayout: false,
  },
  {
    name: "igLogin",
    path: `${commonPath}igLogin`,
    component: lazy(() => import("@pages/IgLogin")),
    isAuthRoute: false,
    includeLayout: false,
  },
  {
    name: "callback",
    path: `${commonPath}callback`,
    component: lazy(() => import("@pages/Callback")),
    isAuthRoute: false,
    includeLayout: false,
  },
  {
    name: "igPlugin",
    path: `${commonPath}igPlugin`,
    component: lazy(() => import("@pages/IgPlugin")),
    isAuthRoute: true,
  },
  {
    name: "lottery",
    path: `${commonPath}lottery`,
    component: lazy(() => import("@pages/Lottery")),
    isAuthRoute: true,
  },
  {
    name: "debug",
    path: `${commonPath}debug`,
    component: lazy(() => import("@pages/Debug")),
    isAuthRoute: true,
  },
];

export { routeList };
