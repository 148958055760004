import { Suspense } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { routeList } from "router";
import { renderRoutes } from "utils";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<CircularProgress />}>
          <Routes>
            {renderRoutes(routeList)}
            <Route path="*" element={<Navigate to={"/lottery"} replace />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
